<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="800px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-1 dialogTitle-text">Slots Booked</div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="
              togglePlayerAvailabilityEventEditModal({ show: false });
              closeCallBackModal();
            "
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <!--   <v-col cols="12" class="px-2">
                <v-text-field
                  label="Task Title"
                  outlined
                  dense
                  class="formFields"
                  color="#7253CF"
                >
                </v-text-field>
              </v-col>-->
          <v-row>
            <v-col cols="4" class="pl-2">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="formatedStartDate"
                    prepend-inner-icon="mdi-calendar-range"
                    label="Month"
                    v-bind="attrs"
                    readonly
                    color="#7253CF"
                    class="formFields"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="start_date"
                  no-title
                  @input="menu = false"
                  type="month"
                  @change="getEventSlotList()"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!--  <v-col cols="4" class="pl-2">
              <v-btn
                color="#38227A"
                dark
                class="text-capitalize px-7 mr-5"
                @click="getEventSlotDetailList()"
                height="45px"
                style="border-radius: 10px; height: 40px"
                >Search</v-btn
              > </v-col
            >-->
          </v-row>
          <div class="table-div">
            <template>
              <!--  <div v-for="monthSlot in slotList" :key="monthSlot.date">
      <h2>{{ monthSlot.date }}</h2>
      <div v-for="slot in monthSlot.slots" :key="slot.start_date">
        <ul>
          <li v-for="detail in slot.details" :key="detail.id">
            Note: {{ detail.note }}
          </li>
        </ul>
         <p>Empty Slots: {{ slot.empty_slots }}</p>
      </div>
    </div>-->
              <div
                v-for="monthSlot in slotList"
                :key="monthSlot.date"
                style="border-bottom: 1px solid black"
              >
                <br />
                <span style="font-size: 19px; font-weight: bold; width: 30%"
                  >{{ monthSlot.date }}
                  <div v-for="slot in monthSlot.slots" :key="slot.start_date">
                    <div
                      v-for="detail in slot.details"
                      :key="detail.id"
                      style="font-size: 15px; font-weight: 400"
                    >
                      <div v-if="detail.note !== null" style="font-size: 17px">
                        {{ detail.note }}
                      </div>
                    </div>

                    <div
                      style="
                        font-size: 16px;
                        font-weight: bold;
                        color: #7253cf;
                        margin-left: -2px;
                      "
                    >
                      (Available Slots-{{ slot.empty_slots }})
                    </div>
                  </div></span
                >
                <br />
                <hr />

                <!--    <span
                v-for="info1 in slot.slot_info.players" :key="info1.id"
               >
  <span v-if="info1.players !== null" style="font-size: 17px;margin-left: 40%;">
                    {{ info1}}
                  </span>
                  </span>-->
              </div>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { API_PLAYER_AVAILBLE_MONTHLY_SLOT_LIST } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import moment from "moment";
import {
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
} from "@/constants/APIKeys";
import { ITEMS, LOADING } from "@/constants/ModuleKeys";
export default {
  name: "ListCalendarManagement",
  components: {},
  data() {
    return {
      loading: false,
      formLoading: false,
      nameFlag: false,
      campaignData: {},
      menu: false,
      menu1: false,
      start_date: "",
      end_date: "",
      players_user_id_lists: [],
      arrayCount: 0,
      slotId: "",
      note: "",
      slotID1: "",
      slotList: [],
      details: [],
      campaignDataTable: {
        headers: [
          {
            width: "10%",
            sortable: false,
            text: "Player Name",
            align: "center",
            style: "font-size: 16px;",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
        ],
        [ITEMS]: [],
        [LOADING]: true,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        itemsPerPage: [10, 15, 20, 30, 50, 100],
      },
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "calendarManagement/getPlayerAvailabiltyEventEdit",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    layout() {
      return this.$route.meta.layout;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.togglePlayerAvailabilityEventEditModal({ show: value });
      },
    },
    type() {
      return this.$store.state.calendarManagement.playerAvailabilityEventEdit
        .type;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Task Added";
      } else {
        return "Task Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add New Task";
        case "edit":
          return "Edit Event";
        default:
          return "";
      }
    },
    formatedStartDate: {
      get() {
        if (this.start_date) {
          return moment(this.start_date).format("MMMM");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formatedEndDate: {
      get() {
        if (this.end_date) {
          return moment(this.end_date).format("YYYY-MM-DD");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    dynamicSlotDetailList() {
      // Generate a new array based on the arrayCount value
      const newArray = [];
      for (let i = 0; i < this.arrayCount; i++) {
        newArray.push({ note: "" }); // You can initialize with default values if needed
      }
      return newArray;
    },
  },

  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
    arrayCount() {
      // Update the slotDetailList when arrayCount changes
      this.slotDetailList = this.dynamicSlotDetailList;
    },
  },
  methods: {
    ...mapActions({
      // sponsor Modal
      togglePlayerAvailabilityEventEditModal:
        "calendarManagement/togglePlayerAvailabilityEventEdit",
      showToast: "snackBar/showToast",
    }),
    openCallBackModal() {
      console.log("open modal");
    },
    closeCallBackModal() {
      (this.campaignDataTable.items = []),
        (this.start_date = ""),
        (this.end_date = ""),
        (this.nameFlag = false);
    },
    reloadFunction() {
      location.reload();
    },
    getEventSlotList() {
      const self = this;
      const successHandler = (res) => {
        self.slotList = res.data.monthly_slots;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      //  formJson["start_date"] = this.start_date;
      formJson["month"] = this.start_date.split("-")[1] || "";
      formJson["year"] = this.start_date.split("-")[0] || "";
      return Axios.request_GET(
        API_PLAYER_AVAILBLE_MONTHLY_SLOT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
  },
};
</script>
<style scoped>
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
</style>
